import React from "react";
import {Heading, Link, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost4 = () => {
    return (
        <Stack spacing={3}>
            <Text>Stump grinding is an essential aspect of landscape maintenance in Columbus, OH. It's not just about
                aesthetics; removing stumps can prevent numerous safety and health issues in your yard.</Text>
            <Heading as="h3" fontSize={'xl'}>The Role of Stump Grinding in Landscape Care:</Heading>
            <List styleType="disc">
                <ListItem>Safety: Stump grinding eliminates tripping hazards and potential homes for pests.</ListItem>
                <ListItem>Aesthetics: Removing unsightly stumps enhances the overall appearance of your
                    landscape.</ListItem>
                <ListItem>Disease Prevention: Stumps can be a source of tree diseases and pests. Grinding them down
                    helps protect your other trees.</ListItem>
                <ListItem>Preparation for New Planting: Stump grinding clears the way for new planting, allowing you to
                    redesign and rejuvenate your landscape.</ListItem>
            </List>
            <Text>Learn more about our stump grinding services and how they can benefit your property by visiting
                our <Link href='/stump-grinding'>stump grinding</Link> page. Columbus Tree Service is here to help you
                maintain a clean and safe landscape in Columbus, OH.</Text>
        </Stack>
    )
}