export const posts = [
    {
        "id": '1',
        "title": 'The Ultimate Guide to Tree Pruning in Columbus',
        "metaTitle": 'Expert Tree Pruning Tips for Columbus Residents | Columbus Tree Service Blog',
        "excerpt": 'Discover the best practices for tree pruning in Columbus, OH, and why it’s crucial for the health and beauty of your trees.',
        "slug": 'ultimate-guide-to-tree-pruning-in-columbus',
        "tags": ['Tree Pruning', 'Columbus Tree Care', 'Arborist Tips'],
        "metaDescription": 'Learn about tree pruning techniques and their importance for maintaining healthy, beautiful trees in Columbus.',
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString()
    },
    {
        "id": '2',
        "title": 'Safe Tree Removal Techniques for Columbus Homeowners',
        "metaTitle": 'How to Safely Remove Trees in Columbus | Columbus Tree Service Blog',
        "excerpt": 'Learn about essential steps and safety precautions for tree removal in Columbus, ensuring a safe environment for your property.',
        "slug": 'safe-tree-removal-techniques-columbus',
        "tags": ['Tree Removal', 'Safety Tips', 'Columbus OH'],
        "metaDescription": 'Explore safe and effective tree removal methods and practices used in Columbus, OH.',
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString()
    },
    {
        "id": '3',
        "title": 'Benefits of Regular Tree Maintenance in Urban Columbus',
        "metaTitle": 'Importance of Tree Maintenance in Columbus | Columbus Tree Service Blog',
        "excerpt": 'Explore the importance of regular tree maintenance in urban areas like Columbus, OH, and its contribution to a healthier, greener community.',
        "slug": 'benefits-regular-tree-maintenance-urban-columbus',
        "tags": ['Tree Maintenance', 'Urban Forestry', 'Columbus Green Spaces'],
        "metaDescription": 'Discover how regular tree maintenance can transform urban areas in Columbus into healthier and more sustainable environments.',
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString()
    },
    {
        "id": '4',
        "title": 'Understanding Stump Grinding and Its Importance in Columbus',
        "metaTitle": 'Your Guide to Stump Grinding in Columbus | Columbus Tree Service Blog',
        "excerpt": 'Find out how stump grinding works in Columbus and why it’s a critical step in maintaining a clean and safe landscape.',
        "slug": 'understanding-stump-grinding-columbus',
        "tags": ['Stump Grinding', 'Landscape Care', 'Columbus Services'],
        "metaDescription": 'Learn about the process and benefits of stump grinding in Columbus, OH, for maintaining a neat and hazard-free landscape.',
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString()
    }
]
