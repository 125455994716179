import React from 'react';
import BlogCards01 from '../Components/BlogCards/BlogCards01';
import PageHeader from '../Components/common/PageHeader';
// import Pagination from '../Components/common/Pagination';
// import LatestNews from "../Components/LatestNews";
import {SiteData} from "../Constants/siteData";
import {Box} from "@chakra-ui/react";
import {SEO} from "../Components/SEO/SEO";
import useBlogPosts from "../Hooks/useBlogPosts";

const Blog01 = () => {
    const {posts} = useBlogPosts()
    return (
        <div className="main_wrapper">
            <SEO title={SiteData.blog.metaData.title} description={SiteData.blog.metaData.description}/>
            <PageHeader header={SiteData.blog.h1} subtitle={SiteData.blog.heading}/>
            <Box className="blog section">
                <div className="container">
                    <div className="blog_grid">
                        <div className="row">
                            {
                                posts.map(data => <BlogCards01
                                    key={data.id}
                                    data={data}
                                />)
                            }
                        </div>
                    </div>
                </div>
            </Box>
        </div>
    );
};

export default Blog01;