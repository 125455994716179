import React from "react";
import {Heading, Link, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost3 = () => {
    return (
        <Stack spacing={3}>
            <Text>Regular tree maintenance is a cornerstone of urban forestry in Columbus, OH. It not only enhances the
                aesthetic appeal of our green spaces but also plays a significant role in creating a healthier urban
                environment.</Text>
            <Heading as="h3" fontSize={'xl'}>Why Regular Tree Maintenance Matters:</Heading>
            <List styleType="disc">
                <ListItem>Tree Health: Regular maintenance helps in early detection and treatment of diseases and pests,
                    ensuring strong and healthy trees.</ListItem>
                <ListItem>Urban Environment: Well-maintained trees contribute to cleaner air and provide essential green
                    spaces in urban Columbus.</ListItem>
                <ListItem>Public Safety: Regular tree care prevents potential hazards like falling branches, ensuring
                    public safety.</ListItem>
                <ListItem>Property Value: Healthy, well-maintained trees can significantly enhance the value and appeal
                    of your property.</ListItem>
            </List>
            <Text>For comprehensive tree care services, visit our <Link href='/tree-health-consulting'>tree
                health consulting</Link> page. Columbus Tree Service is dedicated to nurturing the urban canopy of Columbus,
                OH.</Text>
        </Stack>
    )
}