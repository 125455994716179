export const faqs = [
    {
        "question": "What types of tree services does Top Columbus Tree Service offer?",
        "answer": "Top Columbus Tree Service offers a comprehensive suite of services, including tree trimming and pruning, stump grinding, comprehensive tree maintenance, tree planting and transplanting, tree health consulting, emergency tree services, arborist consultations, and land clearing."
    },
    {
        "question": "How often should I have my trees trimmed in Columbus, OH?",
        "answer": "The frequency of tree trimming can vary depending on the type of tree and its health, but it is generally recommended that trees be trimmed every 1 to 3 years. Our certified arborists can assess your trees and provide a customized trimming schedule to ensure their health and vitality."
    },
    {
        "question": "Can tree removal damage the foundation?",
        "answer": "Yes, tree removal can potentially damage a property's foundation, especially if the roots are deeply embedded and intertwined with the foundation. Our experts take great care to assess and minimize any risk of damage during the tree removal process."
    },
    {
        "question": "Is Top Columbus Tree Service available for emergency tree removal?",
        "answer": "Yes, we provide 24/7 emergency tree removal services to address urgent safety concerns caused by fallen or damaged trees. Our team is equipped to respond quickly and efficiently to prevent further damage or safety risks."
    },
    {
        "question": "How can I tell if a tree is diseased or dying?",
        "answer": "Signs of a diseased or dying tree include discolored leaves, premature leaf drop, dead branches, trunk damage, and fungal growth. Contact us for a professional assessment and appropriate treatment options if you notice these symptoms."
    },
    {
        "question": "Do I need a permit to remove a tree in Columbus, OH?",
        "answer": "In many cases, a permit may be required to remove a tree, mainly if it's within city limits or is a specific size. Our team can help you determine if a permit is needed and assist with the application process."
    },
    {
        "question": "What is stump grinding, and why is it necessary?",
        "answer": "Stump grinding uses specialized machinery to grind down a tree stump below ground level. It's necessary to prevent tripping hazards, deter pests, and clear the space for new plantings or landscaping projects."
    },
    {
        "question": "How does Top Columbus Tree Service contribute to environmental conservation?",
        "answer": "We are committed to eco-friendly practices, including using sustainable methods for tree care, recycling wood and debris, and advising on tree planting to enhance local biodiversity. Our goal is to care for your trees while preserving the environment."
    }
];
